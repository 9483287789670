import React from 'react';
import { Flex, Button, Result } from 'antd';
import SideBar from "../components/SideBar/SideBar";

const App: React.FC = () => (
    <Flex>
        <div style={{ height: '100vh' }}>
            <SideBar></SideBar>
        </div>
        <div id="content-container">
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={<Button type="primary">Back Home</Button>}
            />
        </div>
    </Flex>
);

export default App;