import { Skeleton } from 'antd';
import React, {useEffect} from 'react';
import { useNavigate } from "react-router-dom";

export default function AppIndex() {
    const navigate = useNavigate();

    useEffect(()=> {
        //setTimeout(() => {
        //    navigate("/Login")
        //}, 1000);
        window.location.href = '/Login';
    },[])
    return (
        <div className="AppIndex">
            <Skeleton active paragraph={{ rows: 24 }} />
        </div>
    );
};
