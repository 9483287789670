import { ThemeConfig } from "antd"

const antdConfig: { theme: ThemeConfig } = {
    theme: {
        token: {
            colorPrimary: '#00ae68',
            colorLink: '#00ae68',
        }
    }
}

export default antdConfig